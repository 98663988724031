import { toggleView, setExerciseText, populateExerciseList, initializeWorkout, speakText } from './ui.js';
import { checkWorkoutCompletion } from './cookies.js';
import { startTimer } from './timer.js';
import { showSuccessView } from './endWorkout.js';


document.addEventListener("DOMContentLoaded", () => {
    const startButton = document.getElementById('startButton');

    // Check if the workout is already completed when the page loads
    if (checkWorkoutCompletion()) {

        showSuccessView();
    } else {
        // Setup start button event listener
        populateExerciseList();
        toggleView("workout-container");
        
        startButton.addEventListener("click", () => {
            speakText("Get Ready!");

            startWorkout();
        });
    }
});

// Start Workout
function startWorkout() {

    toggleView("timer-container");

    const selectedFormat = document.querySelector('input[name="format"]:checked').value;
    console.log(selectedFormat);
    setExerciseText("Get Ready!"); 
    initializeWorkout(selectedFormat);
    startTimer();
}
